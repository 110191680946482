import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-steam-error-modal',
  templateUrl: './steam-error-modal.component.html',
  styleUrls: ['./steam-error-modal.component.scss'],
})
export class SteamErrorModalComponent {
  constructor(private readonly dialogRef: MatDialogRef<SteamErrorModalComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
@NgModule({
  imports: [CommonModule, RouterModule, MatIconModule, TranslateModule],
  declarations: [SteamErrorModalComponent],
  exports: [SteamErrorModalComponent],
})
export class SteamErrorModalComponentModule {}
