<app-ui-brand></app-ui-brand>
<div class="tw-mt-8 tw-mb-4">{{ 'LOCAL.MODAL.BANNED.HEADER' | translate }}</div>
<div class="tw-my-2">{{ banReason | translate }}</div>
<div class="tw-my-2">
  {{ 'LOCAL.MODAL.BANNED.TEXT' | translate }}
  <a (click)="openTechSupportDialog()">{{ 'LOCAL.MODAL.BANNED.LINK' | translate }} </a>
</div>
<div class="tw-mt-10">
  <button
    class="btn btn-primary--design"
    (click)="onLogout()"
  >
    <mat-icon
      svgIcon="logout"
      class="!tw-size-5"
    ></mat-icon>
    {{ 'LOGOUT' | translate | titlecase }}
  </button>
</div>
