<div
  class="tw-rounded-2xl tw-p-8 tw-relative tw-flex tw-gap-6 tw-flex-col tw-items-center tw-min-w-80"
  [class]="getDataBasedOnProjectType('market', 'tw-bg-gray-600')"
>
  <mat-icon
    svgIcon="close"
    (click)="close()"
    [style.width]="'1rem'"
    [style.height]="'1rem'"
    class="tw-absolute tw-right-8 tw-top-8 icon-btn-color tw-cursor-pointer"
  />
  <div
    class="tw-flex tw-flex-col tw-items-center tw-justify-center"
    [class]="getDataBasedOnProjectType('tw-gap-6', 'tw-gap-4')"
  >
    <span
      class="tw-text-white"
      [class]="getDataBasedOnProjectType('tw-text-lg/[18px]', ' tw-text-xl/[20px]')"
      >{{ 'SUPPORT.TECH_MODAL.HEADER' | translate }}</span
    >
    <span class="description-color tw-px-6">{{ 'SUPPORT.TECH_MODAL.BODY' | translate }}</span>
  </div>
  <div
    class="tw-w-full tw-cursor-copy tw-py-3 tw-px-4 tw-flex tw-justify-between tw-items-center"
    (click)="onCopy($event)"
    [class]="getDataBasedOnProjectType('mail-block-color tw-rounded-xl', 'tw-bg-gray-700 tw-rounded-lg')"
  >
    <span
      class="tw-text-sm/[20px]"
      [class]="getDataBasedOnProjectType('tw-text-white', 'description-color')"
      >mailto:{{ email }}</span
    >
    <mat-icon
      [svgIcon]="getDataBasedOnProjectType('copy', 'copy-new')"
      class="description-color"
      [class]="!isRenew ? 'tw-rotate-180' : ''"
      [style.width]="getDataBasedOnProjectType('1.25rem', '1rem')"
      [style.height]="getDataBasedOnProjectType('1.25rem', '1rem')"
    />
  </div>
  <a
    class="tw-w-full tw-cursor-pointer tw-text-xs/[20px] tw-rounded-xl tw-py-3 tw-px-6 tw-text-center tw-font-medium link"
    [class]="getDataBasedOnProjectType('market-redirect-color-btn tw-text-gray-950', 'tw-bg-yellow-500 tw-text-gray-800')"
    [href]="redirectLink"
  >
    {{ 'LOCAL.SUPPORT_MODAL.TICKER_BUTTON' | translate }}
  </a>
</div>
