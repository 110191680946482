@if (currentReferral) {
  <div class="promo-modal tw-bg-inherit tw-rounded-[inherit]">
    <!--    <div class="promo-shine">-->
    <!--      <div class="promo-shine&#45;&#45;img"></div>-->
    <!--    </div>-->
    <div class="modal-content">
      <span
        class="close-btn"
        (click)="close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </span>
      <div class="modal-header">
        <h2>
          {{ codeMessage(currentReferral.referrer) | translate }}&nbsp;<span>{{ currentReferral.code | uppercase }}</span>
        </h2>
        @if (currentReferral.referrer) {
          <div class="user-info">
            {{ 'LOCAL.MODAL.REFERRAL_BONUS.FROM' | translate }}
            <app-ui-user-avatar [url]="currentReferral.referrer.user.avatar"></app-ui-user-avatar>
            <div class="user-name">{{ currentReferral.referrer.user.name }}</div>
          </div>
        }
      </div>
      <div class="modal-body">
        <div class="promo-main">
          <div class="promo-main-background"></div>
          <div class="promo-main-amount">
            <div class="flex fx-align-center fx-justify-center">
              @switch (currentReferral.type) {
                @case ('gofast-custom-money') {
                  <div>
                    {{ currentReferral.reward | appCurrency }}
                  </div>
                }
                @case ('money') {
                  <div>
                    {{ currentReferral.reward | appCurrency }}
                  </div>
                }
                @case ('gofast') {
                  <div class="gofast-type">
                    <img src="/assets/img/promocode/promo-case.png" />
                    <span>+</span>
                    <span class="flex fx-column gofast-percent">
                      {{ currentReferral.reward }}%
                      <span class="gofast-percent-text">
                        {{ 'PROMOCODE.BALANCE' | translate }}
                      </span>
                    </span>
                  </div>
                }
                @default {
                  @if (!imageError) {
                    <img
                      class="image"
                      [src]="iconSrc(currentReferral.reward)"
                      (error)="onImgError()"
                      alt
                    />
                  } @else {
                    <div>+ {{ currentReferral.reward }}%</div>
                  }
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (isAuth$ | async) {
      <div class="refil-container">
        <div class="referral-description">
          {{ 'LOCAL.MODAL.REFERRAL_BONUS.DESCRIPTION' | translate }}
          <app-currency
            [value]="1000"
            [cKey]="'fastcoins_refill'"
            [digitsInfo]="'1.1-1'"
          />
        </div>
        <button
          class="btn btn-primary"
          langRouterLink
          [routerLink]="['refill']"
          (click)="close()"
        >
          {{ 'PROMOCODE.REF_TODO' | translate }}
        </button>
      </div>
    } @else {
      <fast-auth-modal></fast-auth-modal>
    }
  </div>
}
<!-- {{ 'LOCAL.PROMOCODE_MODAL.BUTTON_REFILL' | translate }} -->
