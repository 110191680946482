import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, NgModule, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

import { PortalService } from '@app/core/portal-service';

@Component({
  selector: 'app-routable-modal-container',
  templateUrl: './routable-modal-container.component.html',
  styleUrls: ['./routable-modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutableModalContainerComponent {
  public modalPortal$: Observable<Portal<any> | undefined>;
  constructor(private vcr: ViewContainerRef, private injector: Injector, private readonly portalService: PortalService) {
    this.modalPortal$ = this.portalService.routableModalPortal.value$;
  }
}

@NgModule({
  imports: [CommonModule, PortalModule],
  declarations: [RoutableModalContainerComponent],
  exports: [RoutableModalContainerComponent],
})
export class RoutableModalContainerModule {}
