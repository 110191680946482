<div class="rwt-modal-header">
  <div class="rwt-modal-header__wrapper"></div>
  <div class="rwt-modal-header__title">
    {{ 'MARKET.RWT.MODAL.HEADER_TITLE' | translate }}
  </div>
  <div class="rwt-modal-header__wrapper">
    <button
      class="btn btn-sm sq btn-round btn-darken--design rwt-modal-header__btn"
      (click)="closeModal()"
    >
      <mat-icon
        class="icon md"
        svgIcon="close"
      ></mat-icon>
    </button>
  </div>
</div>
<div class="rwt-modal-body">
  <div class="rwt-modal-body__title">
    {{ 'MARKET.RWT.MODAL.BODY_TITLE' | translate }}
  </div>
  <div class="rwt-modal-body__list">
    <div class="rwt-modal-body__point">
      {{ 'MARKET.RWT.MODAL.BODY_P1' | translate }}
      <br />
      <a
        class="rwt-modal-body__point_link"
        langRouterLink
        routerLink="/faq/rwt"
        (click)="redirect()"
      >
        {{ 'MARKET.RWT.MODAL.FAQ_LINK' | translate }}
      </a>
    </div>
    <div class="rwt-modal-body__point">
      {{ 'MARKET.RWT.MODAL.BODY_P2' | translate }}
    </div>
    <div class="rwt-modal-body__point">
      {{ 'MARKET.RWT.MODAL.BODY_P3' | translate }}
    </div>
  </div>
  <button
    class="btn sm btn-semi-round btn-primary--design w-100 fw-500"
    (click)="RWTAgree()"
  >
    {{ 'MARKET.RWT.MODAL.AGREE_BTN' | translate }}
  </button>
  @if (isCheckboxShow) {
    <ng-container [formGroup]="rwtFaqForm">
      <app-ui-checkbox
        formControlName="showModal"
        [labels]="labels"
      />
    </ng-container>
  }
</div>
