import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { IReferralBonus } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-case-bonus-modal',
  templateUrl: './case-bonus-modal.component.html',
  styleUrls: ['./case-bonus-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, TranslateModule, RouterLink, LangRouterModule],
})
export class CaseBonusModalComponent {
  readonly #dialogRef = inject(MatDialogRef<CaseBonusModalComponent>);
  readonly #data = inject<IReferralBonus>(MAT_DIALOG_DATA);

  payload = signal<IReferralBonus | null>(this.#data && this.#data.rewardedCases.length ? this.#data : null);

  close(): void {
    this.#dialogRef.close();
  }
}
