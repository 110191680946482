@if (refillData) {
  <div class="notify-modal__container">
    <button
      class="btn btn-sm sq notify-modal__close-btn"
      (click)="closeModal()"
    >
      <mat-icon
        class="icon"
        [svgIcon]="'close'"
      >
      </mat-icon>
    </button>
    @switch (refillData.paymentStatus) {
      @case (TransactionStatusEnum.RECEIVED) {
        <div class="notify-modal__icon-wrapper">
          <mat-icon
            class="notify-modal__icon"
            [svgIcon]="'balance-received'"
          >
          </mat-icon>
        </div>
        <div class="notify-modal__title">
          {{ 'BALANCE.REFILL.MODAL.REFILL_IN_PROCESS.TRANSACTION_ACCEPT' | translate }}
        </div>
        <div class="notify-modal__description-wrapper">
          <div class="notify-modal__description-title">
            {{ 'BALANCE.REFILL.MODAL.REFILL_IN_PROCESS.REQUEST_PROCESSED' | translate }}
          </div>
        </div>
      }
      @case (TransactionStatusEnum.COMPLETED) {
        <div class="notify-modal__title">
          {{ 'BALANCE.REFILL.MODAL.REFILL_COMPLETED.TITLE' | translate }}
        </div>
        <div class="notify-modal__description-wrapper">
          <div class="notify-modal__description-title">
            {{ 'BALANCE.REFILL.MODAL.REFILL_COMPLETED.TOOLTIP' | translate }}
          </div>
          @if (refillData.params && refillData.params.amount) {
            <div class="notify-modal__description-amount">
              {{ refillData.params.amount | appCurrency }}
            </div>
          }
        </div>
      }
      @case (TransactionStatusEnum.ERRORED) {
        <div class="notify-modal__icon-wrapper">
          <mat-icon
            class="notify-modal__icon"
            [svgIcon]="'balance-errored'"
          >
          </mat-icon>
        </div>
        <div class="notify-modal__title">
          {{ 'BALANCE.REFILL.MODAL.REFILL_ISSUES.TITLE' | translate }}
        </div>
        <div class="notify-modal__description-wrapper">
          <div class="notify-modal__description-title">
            {{ 'BALANCE.REFILL.MODAL.REFILL_ISSUES.TEXT' | translate }}
          </div>
        </div>
      }
      @case (TransactionStatusEnum.PAYOUT_SUCCESSFUL) {
        <div class="notify-modal__icon-wrapper">
          <mat-icon
            class="notify-modal__icon"
            [svgIcon]="'balance-received'"
          >
          </mat-icon>
        </div>
        <div class="notify-modal__title">
          {{ 'WITHDRAW.ACCEPTED' | translate }}
        </div>
        <div class="notify-modal__description-wrapper">
          <div class="notify-modal__description-title">
            {{ 'WITHDRAW.ACCEPTED_DESCRIPTION' | appCurrencyTranslate: refillData.params }}
          </div>
          <div class="notify-modal__description-title">
            {{ 'WITHDRAW.ACCEPTED_DESCRIPTION_ADDITIONAL' | translate }}
          </div>
        </div>
      }
      @case (TransactionStatusEnum.PAYOUT_ERRORED) {
        <div class="notify-modal__icon-wrapper">
          <mat-icon
            class="notify-modal__icon"
            [svgIcon]="'balance-errored'"
          >
          </mat-icon>
        </div>
        <div class="notify-modal__title">
          {{ 'WITHDRAW.BLOCKED' | translate }}
        </div>
        <div class="notify-modal__description-wrapper">
          @if (refillData.customMessage) {
            <div class="notify-modal__description-title">
              {{ refillData.customMessage | appCurrencyTranslate: refillData.params }}
            </div>
          }
        </div>
      }
    }
    <button
      class="btn btn-semi-round md btn-inversion-yellow--design"
      (click)="closeModal()"
    >
      {{ 'BALANCE.REFILL.MODAL.SUCCESS_BUTTON' | translate }}
    </button>
  </div>
}
