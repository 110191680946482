<iframe
  src="https://gleam.io/VTGxT/lunar-new-year-giveaway"
  frameBorder="0"
  allowfullscreen
></iframe>
<button
  class="close-btn btn-outline-secondary"
  (click)="onClose()"
>
  <mat-icon svgIcon="close"> </mat-icon>
</button>
