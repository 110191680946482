import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IUserSelfExclusionData } from '@dev-fast/types';
import { CountdownComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CommonStoreService } from '@app/core-state-common';

@Component({
  selector: 'app-self-exclusion-modal',
  templateUrl: './self-exclusion-modal.component.html',
  styleUrls: ['./self-exclusion-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfExclusionModalComponent {
  payload!: IUserSelfExclusionData | null;
  time = 59000;
  constructor(
    private readonly dialogRef: MatDialogRef<SelfExclusionModalComponent>,
    private commonService: CommonStoreService,

    @Inject(MAT_DIALOG_DATA) private data: IUserSelfExclusionData,
  ) {
    this.payload = data;
    this.confirmSelfBlock();
  }

  close(): void {
    this.dialogRef.close();
  }
  sendConfirmAgain(): void {
    this.time = 59000;
    this.confirmSelfBlock();
  }
  private confirmSelfBlock(): void {
    if (this.payload) {
      this.commonService.demandSelfExclusion(this.payload.duration);
      this.time = 59000;
    }
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, CountdownComponentModule],
  declarations: [SelfExclusionModalComponent],
  exports: [SelfExclusionModalComponent],
})
export class SelfExclusionModalModule {}
