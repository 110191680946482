@if (payload(); as bonus) {
  <div class="promo-bonus-modal tw-min-w-[37.25rem] tw-bg-inherit tw-rounded-[inherit]">
    <div class="modal-content tw-flex tw-flex-col tw-gap-4">
      <span
        class="close-btn"
        (click)="close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </span>
      <div class="modal-header">
        <h2>{{ 'PROMOCODE.REF_PAID' | translate }}</h2>
      </div>
      @for (item of bonus.rewardedCases; track item) {
        <div class="modal-body">
          <div class="promo-main">
            <div class="promo-main-background"></div>
            <div class="promo-main-amount tw-flex tw-justify-center">
              <img
                src="/assets/img/promocode/promo-case.png"
                alt="promo-case"
              />
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-p-8">
          <button
            class="btn btn-primary--design !tw-rounded-xl"
            (click)="close()"
            langRouterLink
            [routerLink]="['game/cases/case/' + item.caseId]"
          >
            {{ 'LOCAL.NY_EVENT.OPEN_CASE' | translate }}
          </button>
        </div>
      }
    </div>
  </div>
}
