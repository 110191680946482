import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { replacementScaleAnimation } from '@app/ui/animations';

import { CaseInfoSAComponent } from './case-info/case-info.component';

@Component({
  selector: 'app-case-info-modal',
  templateUrl: './case-info-modal.component.html',
  styleUrls: ['./case-info-modal.component.scss'],
  standalone: true,
  imports: [MatIconModule, CaseInfoSAComponent],
  animations: [replacementScaleAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseInfoModalSAComponent {
  readonly #dialogRef = inject(MatDialogRef<CaseInfoModalSAComponent>);
  readonly data = inject<{ caseId: number; showRevisionSelector: boolean }>(MAT_DIALOG_DATA);

  readonly iscCaseLoaded = signal<boolean>(false);

  onCloseClick(): void {
    this.#dialogRef.close();
  }
}
