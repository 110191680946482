@if (payload) {
  <div class="flex fx-column fx-align-center gap-8">
    <mat-icon
      svgIcon="lock--design"
      class="exclusionIcon"
    ></mat-icon>
    <div class="headerText tw-font-bold">{{ 'Blocking pending confirmation' | translate | uppercase }}</div>
    <div class="bodyText tw-font-normal">{{ 'We sent a request to confirm the blocking to' | translate }} {{ payload.address }}</div>
    <button
      class="btn btn-primary tw-font-bold"
      (click)="sendConfirmAgain()"
      [disabled]="time"
    >
      @if (time) {
        {{ 'Send again in' | translate }}
        <app-ui-countdown
          [until]="60000"
          [minutes]="0.92"
          (countdownFinished)="time = 0"
        >
        </app-ui-countdown>
      } @else {
        {{ 'Send again' | translate }}
      }
    </button>
  </div>
}
