import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICaseType } from '@dev-fast/types';
import { BehaviorSubject, switchMap } from 'rxjs';

import { CaseRevisionService } from './case-revision.service';

@Component({
  selector: 'app-case-revision',
  templateUrl: './case-revision.component.html',
  styleUrls: ['./case-revision.component.scss', './case-revision.media.scss'],
})
export class CaseRevisionComponent {
  activeRevision$: BehaviorSubject<number> = new BehaviorSubject(0);
  caseRevision$ = this.activeRevision$.pipe(
    switchMap((activeRevision) => this.caseRevisionService.getCaseRevisionById(this.caseId, activeRevision)),
  );
  caseTypeEnum: typeof ICaseType = ICaseType;

  constructor(
    public dialogRef: MatDialogRef<CaseRevisionComponent>,
    @Inject(MAT_DIALOG_DATA) private caseId: number,
    private caseRevisionService: CaseRevisionService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
  getCaseRevision(range: number): void {
    this.activeRevision$.next(range);
  }
}
