import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ModalNames } from '@dev-fast/types';
import { BrandComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ChatService } from '@app/core/chatra-service';
import { OpenModal } from '@app/core/state/modals';
import { LangRouterModule } from '@app/shared/directives';

import { BanService } from './ban.service';

@Component({
  selector: 'app-ban',
  templateUrl: './ban.component.html',
  styleUrls: ['./ban.component.scss'],
  providers: [BanService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanComponent {
  readonly #dialogRef = inject(MatDialogRef<BanComponent>);
  readonly #chatService = inject(ChatService);
  readonly #banService = inject(BanService);
  readonly banReason: string = inject(MAT_DIALOG_DATA);

  onLogout(): void {
    this.#banService.logout();
  }

  onClose(): void {
    this.#dialogRef.close();
  }

  openTechSupportDialog(): void {
    if (this.#chatService.hasChat()) {
      this.#chatService.openChat();
      return;
    }
    this.openModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  }

  @Dispatch() private openModal = (name: ModalNames, payload?: any): OpenModal => new OpenModal(name, payload);
}

@NgModule({
  imports: [CommonModule, BrandComponentModule, MatIconModule, TranslateModule, RouterLink, LangRouterModule],
  declarations: [BanComponent],
  exports: [BanComponent],
})
export class BanComponentModule {}
