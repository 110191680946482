import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IPromoCode, IReferrerUser } from '@dev-fast/types';
import { UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AuthModalsModule, AuthService } from '@app/auth';
import { CurrencyComponent } from '@app/core/currency';
import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrls: ['./referral-modal.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralModalComponent {
  isAuth$: Observable<boolean | null>;
  currentReferral!: IPromoCode | null;
  imageError = false;

  constructor(
    private readonly authService: AuthService,
    private readonly dialogRef: MatDialogRef<ReferralModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IPromoCode,
  ) {
    this.currentReferral = data;
    this.isAuth$ = this.authService.isAuth$;
  }

  close(): void {
    this.dialogRef.close();
  }
  codeMessage(referrerUser: IReferrerUser | undefined): string {
    if (referrerUser) {
      return 'PROMOCODE.REF_USED';
    }
    return 'PROMOCODE.CODE_USED';
  }
  onImgError(): void {
    this.imageError = true;
  }
  iconSrc(reward: number): string {
    return `https://d2lomvz2jrw9ac.cloudfront.net/common/discount/${reward}.png`;
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    AuthModalsModule,
    UserAvatarModule,
    AppCurrencyModule,
    TranslateModule,
    LangRouterModule,
    CurrencyComponent,
  ],
  declarations: [ReferralModalComponent],
  exports: [ReferralModalComponent],
})
export class ReferralModalComponentModule {}
