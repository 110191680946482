@if (user$ | async; as user) {
  <button
    class="btn btn-sm sq btn-round btn-transparent-dark--design close-btn"
    (click)="close()"
  >
    <mat-icon
      [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
      class="close-icon"
      [svgIcon]="'close'"
    >
    </mat-icon>
  </button>
  <div class="wrapper">
    <div class="left-block">
      <div class="user-country">
        @if (user.country) {
          <img
            class="user-country-img"
            [src]="getUserCountry(user)"
            alt=""
          />
        }
      </div>
      <app-ui-user-avatar
        [noChevron]="true"
        [url]="user.avatar"
        [level]="user.experience?.level"
        [type]="user.profileType"
        [levelLabelOn]="true"
      ></app-ui-user-avatar>
    </div>
    <div class="right-block">
      <div class="user-name">{{ user.name }}</div>
      <div class="info info-wrapper">
        <div class="info-labels">
          <span class="info-label">ID:</span>
          <span class="info-label">{{ 'USER_PANEL.FIRST_GAME' | translate }}:</span>
        </div>
        <div class="info-values">
          <span class="info-value">{{ user.id }}</span>
          <span class="info-value">{{ user.activity?.registered | date: 'dd.MM.yyyy' }}</span>
        </div>
      </div>
      @if (user.providers.length) {
        <div class="profile-link">
          @for (provider of user.providers; track provider) {
            @if (provider.name === socialTypes.Vkontakte) {
              <a
                href="{{ 'https://vk.com/id' + provider.id }}"
                [target]="'blank'"
                class="link"
                (click)="close()"
              >
                <mat-icon [svgIcon]="socialTypes.Vkontakte"></mat-icon>
              </a>
            }
            @if (provider.name === socialTypes.Steam) {
              <a
                href="{{ 'https://steamcommunity.com/profiles/' + provider.id }}"
                [target]="'blank'"
                class="link"
                (click)="close()"
              >
                <mat-icon [svgIcon]="socialTypes.Steam"></mat-icon>
              </a>
            }
          }
        </div>
      }
    </div>
  </div>
  <div class="activities">
    @if (!user.privacy?.statisticsIsHidden) {
      @for (item of user.statistics; track item) {
        @if (getGameTitle(item.key) | async; as game) {
          <app-best-card
            [item]="item"
            [game]="game"
          >
          </app-best-card>
        }
      }
    } @else {
      @for (item of hiddenItemsArr; track item) {
        @if (getGameTitle(hiddenItem.key) | async; as game) {
          <app-best-card
            class="blur"
            [item]="hiddenItem"
            [game]="game"
          ></app-best-card>
        }
      }
      <div class="statistics-hidden-wrapper">
        <h2>{{ 'USER_PANEL.STATISTIC_HIDDEN' | translate }}</h2>
      </div>
    }
  </div>
} @else {
  <span
    class="icomoon icon-close close"
    (click)="close()"
  ></span>
  <div class="wrapper">
    <div class="left-block">
      <div class="user-country">
        <ngx-skeleton-loader
          animation="progress-dark"
          appearance="circle"
          [theme]="{
            width: '20px',
            height: '20px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader
        animation="progress-dark"
        appearance="circle"
        [theme]="{
          width: '100px',
          height: '100px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="right-block">
      <div class="user-name">
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            width: '200px',
            'border-radius': '0',
            height: '16px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="info-wrapper">
        <div class="info-labels">
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '70px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '125px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="info-values">
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '70px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            animation="progress-dark"
            [theme]="{
              width: '125px',
              'border-radius': '0',
              height: '14px',
              margin: '0'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="profile-link">
        <ngx-skeleton-loader
          animation="progress-dark"
          [theme]="{
            width: '100px',
            'border-radius': '0',
            height: '14px',
            margin: '0'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
}
