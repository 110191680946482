import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IPublicGameStatistic } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';

@Component({
  selector: 'app-best-card',
  templateUrl: './best-card.component.html',
  styleUrls: ['./best-card.component.scss'],
  standalone: true,
  imports: [DatePipe, CurrencyComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestCardComponent {
  readonly item = input<IPublicGameStatistic>();
  readonly game = input<string>();

  stringTransformator(value: string): string {
    return value.split(' ').join('_').toUpperCase();
  }
  isDateCehck(value: any): boolean {
    return typeof value === 'string';
  }
}
