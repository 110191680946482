<div
  class="modal__wrapper"
  @replacementScaleAnimation
>
  <app-case-info
    [showRevisionSelector]="data.showRevisionSelector"
    [caseItemId]="data.caseId"
    (caseLoaded)="iscCaseLoaded.set(true)"
  >
  </app-case-info>
  @if (iscCaseLoaded()) {
    <button
      class="!tw-absolute tw-right-2 tw-top-2 tw-z-10 btn btn-round btn-md sq btn-transparent-darken--design"
      (click)="onCloseClick()"
    >
      <mat-icon
        class="icon"
        svgIcon="close"
      ></mat-icon>
    </button>
  }
</div>
