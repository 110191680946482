import { inject, Injectable } from '@angular/core';
import { CasesBackendService } from '@dev-fast/backend-services';
import type { ICaseItemDtoV2, ICaseRevisionInfo } from '@dev-fast/types';
import { CASE_BATTLE_SETTINGS, ModalNames, PROJECT } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { type Observable, map } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { AddCase, CaseBattleSelectedCasesState, RemoveCaseById } from '@app/core/state/games-store';
import { CloseModal, OpenModal } from '@app/core/state/modals';

@Injectable()
export class CaseInfoService {
  // Подключение сервисов
  readonly #casesApiService = inject(CasesBackendService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #store = inject(Store);

  // Переменные сервисов
  readonly caseIds$ = this.#store.select(CaseBattleSelectedCasesState.caseIds);
  readonly cases$ = this.#store.select(CaseBattleSelectedCasesState.cases);
  readonly #project = this.#environmentService.getEnvironment().PROJECT;

  readonly casesDataForModal$ = (caseId: number | undefined): Observable<{ amount: number; canAdd: boolean }> =>
    this.caseIds$.pipe(
      map((ids) => {
        if (!caseId) {
          return { amount: ids.length, canAdd: false };
        }

        return { amount: ids.length, canAdd: ids.length < CASE_BATTLE_SETTINGS.maxItems };
      }),
    );

  @Dispatch() addCase = (caseItem: ICaseItemDtoV2): AddCase => new AddCase(caseItem);
  @Dispatch() closeModal = (modalName: ModalNames): CloseModal => new CloseModal(modalName);
  @Dispatch() navigate = (path: any[]): Navigate => new Navigate(path);
  @Dispatch() openModal = (modalName: ModalNames, payload?: any): OpenModal => new OpenModal(modalName, payload);
  @Dispatch() removeCaseById = (caseId: number): RemoveCaseById => new RemoveCaseById(caseId);

  addCaseAndNavigateToGame(caseItem: ICaseItemDtoV2): void {
    this.addCase(caseItem);
    this.closeModal(ModalNames.CASE_REVISION);

    if (this.#project === PROJECT.MARKET) {
      this.navigate(['/case-battle']);
    } else {
      this.navigate(['/game/case-battle']);
    }
  }

  getDetailedCase(caseId: number, revisionIndex: number): Observable<ICaseRevisionInfo> {
    return this.#casesApiService.getCaseRevisionById(caseId, revisionIndex);
  }

  navigateToCaseItem(caseId: number): void {
    if (this.#project === PROJECT.MARKET) {
      this.closeModal(ModalNames.CASE_REVISION);
      this.openModal(ModalNames.CASE, caseId);
    } else {
      this.navigate(['/game/cases/case', caseId]);
      this.closeModal(ModalNames.CASE_REVISION);
    }
  }
}
