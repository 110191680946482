import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-lottery-rules-modal',
  templateUrl: './lottery-rules-modal.component.html',
  styleUrls: ['./lottery-rules-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotteryRulesModalComponent {}

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [LotteryRulesModalComponent],
  exports: [LotteryRulesModalComponent],
})
export class LotteryRulesModalComponentModule {}
