<a
  tabindex=""
  class="close_modal outer_btn"
></a>
<div class="bbm-modal__section">
  <iframe
    width="853"
    height="480"
    [src]="VIDEO_URL"
    frameborder="0"
    allowfullscreen
  ></iframe>
</div>
