import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { INotifyData, TransactionStatus } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule, AppCurrencyTranslateModule } from '@app/shared/pipes';

@Component({
  selector: 'app-transactions-dialog-component',
  templateUrl: './transactions-dialog.component.html',
  styleUrls: ['./transactions-dialog.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule, AppCurrencyModule, AppCurrencyTranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsDialogComponent {
  @Input() refillData: INotifyData | undefined;
  @Output() oncloseDialog: EventEmitter<void> = new EventEmitter();

  TransactionStatusEnum: typeof TransactionStatus = TransactionStatus;

  closeModal(): void {
    this.oncloseDialog.emit();
  }
}
