import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-gleam-modal',
  templateUrl: './gleam-modal.component.html',
  styleUrls: ['./gleam-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GleamModalComponent {
  constructor(private readonly dialogRef: MatDialogRef<GleamModalComponent>) {}
  onClose(): void {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [GleamModalComponent],
  exports: [GleamModalComponent],
})
export class GleamModalComponentModule {}
