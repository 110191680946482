import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserApiService } from '@dev-fast/backend-services';
import { GameMode, IPublicGameStatistic, IPublicUserDetailed, SocialType } from '@dev-fast/types';
import { Store } from '@ngxs/store';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GamesState } from '@app/core/state/games-store';

@Component({
  selector: 'app-user-profile-new',
  templateUrl: './user-profile-new.component.html',
  styleUrls: ['./user-profile-new.component.scss', './user-profile-new.component.media.scss'],
})
export class UserProfileNewComponent {
  userId: number;
  $user: Observable<IPublicUserDetailed | null>;
  hiddenItemsArr: unknown[];
  hiddenItem: IPublicGameStatistic;
  socialTypes = SocialType;
  country!: string | null;

  constructor(
    public dialogRef: MatDialogRef<UserProfileNewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { userId: number },
    private userService: UserApiService,
    private store: Store,
  ) {
    this.userId = data.userId;
    // FIXME мне не нравится что тут делается запрос и таким образом. Но пока ничего лучше в голову не пришло
    this.$user = this.userService
      .getUserProfile<IPublicUserDetailed>(this.userId, { needDetailedInfo: true })
      .pipe(catchError((error: HttpErrorResponse) => of(null)));
    this.hiddenItemsArr = new Array(6);
    this.hiddenItem = {
      key: 'baccarat',
      mode: GameMode.PVE,
      top: {
        isCurrency: true,
        key: 'baccarat_max_bet',
        title: 'Max bet',
        value: '9999',
      },
    };
  }
  getGameTitle(key: string): Observable<string> {
    return this.store.select(GamesState.gameTitle).pipe(map((filterFn) => filterFn(key)));
  }

  close(): void {
    this.dialogRef.close();
  }
}
