import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import type { ICaseItemDtoV2, ICaseRevisionInfo } from '@dev-fast/types';
import { ICaseType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { AutocompleteMenuModule, ButtonComponent, CaseCardComponentModule } from '@app/ui/components/index';
import type { ISelectionMenuItem } from '@app/ui/components/lib/form-elements/autocomplete-menu/shared';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutocompleteMenuModule,
    ButtonComponent,
    CaseCardComponentModule,
    CurrencyComponent,
    DatePipe,
    MatIconModule,
    NgTemplateOutlet,
    TranslateModule,
  ],
  selector: 'app-ui-case-header-info',
  standalone: true,
  styleUrl: './case-info-header.component.scss',
  templateUrl: './case-info-header.component.html',
})
export class CaseInfoHeaderComponent {
  readonly revisionItems = input<ICaseItemDtoV2[]>([]);
  readonly caseItem = input<ICaseItemDtoV2 | null>(null);
  readonly caseRevision = input<ICaseRevisionInfo | null>(null);
  readonly casesAdded = input.required<{ amount: number; canAdd: boolean }>();
  readonly caseRevisionIndex = input<number>(0);
  readonly showRevisionSelector = input<boolean>(true);
  readonly isTable = input<boolean>(true);
  readonly revisionsList = signal<ISelectionMenuItem[]>([]);

  readonly onaddCase = output<ICaseItemDtoV2>();
  readonly onremoveCase = output<number>();
  readonly onnavigateToCase = output<number>();
  readonly onselectRevision = output<number>();
  readonly ontoggleView = output<void>();

  readonly CaseTypeEnum: typeof ICaseType = ICaseType;

  constructor() {
    effect(
      () => {
        const currentRevision = this.caseRevision();
        if (currentRevision && this.showRevisionSelector()) {
          this.#revisionsListConstructor(currentRevision);
        }
      },
      { allowSignalWrites: true },
    );
  }

  #revisionsListConstructor(caseRevision: ICaseRevisionInfo): void {
    this.revisionsList.set(
      caseRevision.revisionIds.reduce((acc: ISelectionMenuItem[], cur) => {
        return [...acc, { name: cur.toString(), title: cur.toString() }];
      }, []),
    );
  }

  addCase(caseItem: ICaseItemDtoV2): void {
    this.onaddCase.emit(caseItem);
  }

  // FIXME: неиспользуемый метод
  removeCase(caseId: number): void {
    this.onremoveCase.emit(caseId);
  }

  getCaseRevision(revisionIndex: number): void {
    this.onselectRevision.emit(revisionIndex);
  }

  navigateToCase(caseId: number): void {
    this.onnavigateToCase.emit(caseId);
  }

  toggleListView(): void {
    this.ontoggleView.emit();
  }
}
