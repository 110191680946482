@if (item(); as item) {
  <div class="widget-container-best-card">
    <div class="widget-content-best-card">
      <h2 class="widget-content-best-card__header">
        {{ 'USER_PANEL.' + stringTransformator(item.top.title) | translate }}
      </h2>
      <div class="widget-content-best-card__game">{{ game() }}</div>
    </div>
    <div
      class="statistics-widget"
      [class.additional]="item.top.additional"
    >
      @if (item.top.isCurrency) {
        <div class="statistics-widget-value">
          <app-currency [value]="+item.top.value"></app-currency>
        </div>
      }
      @if (!item.top.isCurrency) {
        <div class="statistics-widget-value">
          {{ item.top.value }}
        </div>
      }
      @if (item.top.additional && isDateCehck(item.top.additional.value)) {
        <div class="statistics-widget-description">
          <div class="descriprion-item">
            <div class="descriprion-item-title">
              {{ 'USER_PANEL.' + stringTransformator(item.top.additional.title) | translate }}
            </div>
            <div class="descriprion-item-value">
              @if (item.top.additional.title === 'Max profit bet') {
                <app-currency [value]="+item.top.additional.value"></app-currency>
              }
              @if (item.top.additional.title === 'Max bet date' || 'Max win date') {
                {{ item.top.additional.value | date: 'dd.MM.yyyy' }}
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
