<!-- <ng-scrollbar> -->
<iframe
  [src]="urlSafe"
  frameBorder="0"
  allowfullscreen
></iframe>
<!-- </ng-scrollbar> -->

<button
  class="close-btn"
  (click)="onClose()"
></button>
