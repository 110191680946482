import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent {
  readonly VIDEO_URL: SafeUrl;
  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: string,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.VIDEO_URL = sanitizer.bypassSecurityTrustResourceUrl(data + '?rel=0&autoplay=1&fs=0');
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [VideoModalComponent],
  exports: [VideoModalComponent],
})
export class VideoModalComponentModule {}
