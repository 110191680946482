<div class="modal-dialog modal-content refill-bonus-rules-modal">
  <h2 class="refill-bonus-rules__header">
    {{ 'LOCAL.MODAL.REGULAR_RAFFLE.MAIN_HEADER' | translate }}
  </h2>

  <div class="refill-bonus-rules-container">
    <div class="refill-bonus-rules__number">1</div>
    <p class="refill-bonus-rules-wrapper">
      <span class="refill-bonus-rules__header free">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.HOURLY_HEADER' | translate }}
      </span>
      <span class="refill-bonus-rules__description">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.HOURLY_TEXT' | translate }}
      </span>
    </p>
  </div>
  <div class="refill-bonus-rules-container">
    <div class="refill-bonus-rules__number">2</div>
    <p class="refill-bonus-rules-wrapper">
      <span class="refill-bonus-rules__header deposit">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.REFILL_HEADER' | translate }}
      </span>
      <span class="refill-bonus-rules__description">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.REFILL_TEXT' | translate }}
      </span>
    </p>
  </div>
  <div class="refill-bonus-rules-container">
    <div class="refill-bonus-rules__number">3</div>
    <p class="refill-bonus-rules-wrapper">
      <span class="refill-bonus-rules__header weekly">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.WEEKLY_HEADER' | translate }}
      </span>
      <span
        class="refill-bonus-rules__description"
        [innerHtml]="'LOCAL.MODAL.REGULAR_RAFFLE.WEEKLY_TEXT' | translate"
      >
      </span>
    </p>
  </div>
  <div class="refill-bonus-rules-container">
    <div class="refill-bonus-rules__number">4</div>
    <p class="refill-bonus-rules-wrapper">
      <span class="refill-bonus-rules__header monthly">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.MONTHLY_HEADER' | translate }}
      </span>
      <span
        class="refill-bonus-rules__description"
        [innerHtml]="'LOCAL.MODAL.REGULAR_RAFFLE.MONTHLY_TEXT' | translate"
      >
      </span>
    </p>
  </div>
  <div class="refill-bonus-rules-container refill-bonus-rules__attention">
    <p class="refill-bonus-rules-wrapper">
      <span class="refill-bonus-rules__description text-color-primary">
        {{ 'LOCAL.MODAL.REGULAR_RAFFLE.REGULAR_RAFFLE_SUMMARY' | translate }}
      </span>
    </p>
  </div>
  <!--  <a class="btn btn-primary refill-bonus-rules-button" href="/game/classic">{ 'Make bet' | translate }</a>-->
</div>
